import React from 'react';
import './Common.css'
import Footer from './Footer';
import Navbar from '../components/Navbar';

const TermsAndConditions = () => {
    return (
        <>
        <Navbar />
        
        <section id='main-section' className="termsAndConditionsSection">
            <h3>Terms and Conditions</h3>
            <p><strong>Effective Date:</strong> [Insert Date]</p>
            <h4>1. Introduction:</h4>
            <p>These Terms and Conditions govern the relationship between Aekaksh School ("Franchisor") and the franchisee ("You," "Franchisee") regarding the operation of an Aekaksh School franchise. By entering into a franchise agreement, you agree to comply with these terms.</p>
            <h4>2. Franchise Agreement:</h4>
            <ul>
                <li><strong>Grant of Franchise:</strong> Upon acceptance of your application and payment of the applicable fees, you are granted the right to operate an Aekaksh School franchise under the terms set forth in the franchise agreement.</li>
                <li><strong>Franchise Territory:</strong> You will be assigned an exclusive territory where you can operate your franchise, as specified in your agreement.</li>
                <li><strong>Term:</strong> The franchise agreement is valid for [Insert Term Duration] years, with an option to renew subject to mutual agreement.</li>
            </ul>
            <h4>3. Obligations of the Franchisee:</h4>
            <ul>
                <li><strong>Compliance:</strong> You agree to operate your franchise in accordance with the policies, procedures, and standards established by Aekaksh School.</li>
                <li><strong>Fees:</strong> You agree to pay all applicable fees, including the franchise fee, royalty payments, and any other charges as outlined in the agreement.</li>
                <li><strong>Use of Trademarks:</strong> You are granted the right to use the Aekaksh School trademarks, logos, and branding materials solely for the operation of your franchise.</li>
                <li><strong>Training:</strong> You are required to complete the initial training program and participate in ongoing training sessions as specified by the Franchisor.</li>
            </ul>
            <h4>4. Obligations of the Franchisor:</h4>
            <ul>
                <li><strong>Support:</strong> Aekaksh School will provide you with ongoing support, including training, marketing assistance, and operational guidance.</li>
                <li><strong>Marketing:</strong> The Franchisor will manage national marketing campaigns and provide you with materials for local promotion.</li>
                <li><strong>Product and Service Updates:</strong> Aekaksh School will keep you informed of any changes or updates to the curriculum, services, or operational procedures.</li>
            </ul>
            <h4>5. Intellectual Property:</h4>
            <p>All intellectual property rights, including trademarks, logos, and proprietary information, remain the exclusive property of Aekaksh School. You agree not to use any intellectual property for purposes outside the scope of your franchise agreement.</p>
            <h4>6. Termination:</h4>
            <ul>
                <li><strong>By Franchisee:</strong> You may terminate the agreement by providing written notice at least [Insert Notice Period] days in advance.</li>
                <li><strong>By Franchisor:</strong> Aekaksh School may terminate the agreement if you breach any of the terms and conditions or fail to comply with the operational standards.</li>
                <li><strong>Post-Termination:</strong> Upon termination, you must cease all use of Aekaksh School trademarks and return any proprietary materials to the Franchisor.</li>
            </ul>
            <h4>7. Dispute Resolution:</h4>
            <p>Any disputes arising out of or in connection with this agreement will be resolved through mediation. If mediation fails, the dispute will be submitted to arbitration in [Insert Jurisdiction].</p>
            <h4>8. Governing Law:</h4>
            <p>This agreement is governed by the laws of [Insert Jurisdiction].</p>
            <p><strong>Contact Us:</strong> For any inquiries regarding these Terms and Conditions, please contact us at [Insert Contact Information].</p>
        </section>
        
        <Footer />
        
        </>
    );
};

export default TermsAndConditions;
