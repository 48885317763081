// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './components/Home';
// import PrivacyPolicy from './tc/PrivacyPolicy';
// import TermsAndConditions from './tc/TermsAndConditions';
// import RefundPolicy from './tc/RefundPolicy';
// import Footer from './tc/Footer';


// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           {/* <Route path="/business-proposal" element={<BusinessProposal />} /> */}
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
//           <Route path="/refund-policy" element={<RefundPolicy />} />
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components
import Home from './components/Home';
import PrivacyPolicy from './tc/PrivacyPolicy';
import TermsAndConditions from './tc/TermsAndConditions';
import RefundPolicy from './tc/RefundPolicy';
import NewsBlogs from './tc/NewsBlogs';
import FloatingCallButton from './FloatingCallButton/FloatingCallButton';
import ContactPage from './components/ContactPage';
// import Footer from './tc/Footer';

function App() {
  return (
    <React.Fragment>
    <Routes>
    <Route path="/" element={<Home />} />
          <Route path="/newsblogs" element={<NewsBlogs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact" element={<ContactPage />} />
          
        </Routes>
        <FloatingCallButton />
    </React.Fragment>
  );
}

export default App;
