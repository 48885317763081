import React from 'react';
import Navbar from '../components/Navbar';
import Footer from './Footer';

const NewsBlogs = () => {
  const blogPosts = [
    {
      id: 1,
      title: 'Unlock the Benefits of Owning an Aekaksh Play School Franchise',
      content: `Aekaksh Play School offers a unique franchise model that empowers aspiring entrepreneurs to build a successful business in the education sector...`,
      date: 'October 12, 2024',
    },
    {
      id: 2,
      title: 'Aekaksh Franchise Model: A Turnkey Solution for Aspiring Entrepreneurs',
      content: `At Aekaksh, we understand that starting a play school from scratch can be daunting. That's why our franchise model is a complete turnkey solution...`,
      date: 'October 11, 2024',
    },
    {
      id: 3,
      title: 'Special Offers for Women Entrepreneurs: Aekaksh Empowers You!',
      content: `Aekaksh Play School is committed to empowering women entrepreneurs by offering exclusive benefits and support...`,
      date: 'October 10, 2024',
    },
    {
      id: 4,
      title: 'Why Aekaksh Play School is the Perfect Franchise for You',
      content: `The Aekaksh franchise model is ideal for anyone passionate about education and looking to make a meaningful impact...`,
      date: 'October 9, 2024',
    },
    {
      id: 5,
      title: 'How Aekaksh Supports You Every Step of the Way',
      content: `From the moment you decide to become an Aekaksh franchisee, our team is with you every step of the way...`,
      date: 'October 8, 2024',
    },
    {
      id: 6,
      title: 'Aekaksh Play School: A Proven Business Model for Success',
      content: `Our franchise model is built on years of experience and a deep understanding of early childhood education...`,
      date: 'October 7, 2024',
    },
    {
      id: 7,
      title: 'Take Advantage of Aekaksh\'s Limited-Time Offers for New Franchisees',
      content: `To celebrate our expansion in Hyderabad, Aekaksh Play School is offering limited-time incentives for new franchisees...`,
      date: 'October 6, 2024',
    },
    {
      id: 8,
      title: 'The Aekaksh Advantage: Training, Support, and Innovation',
      content: `One of the key benefits of the Aekaksh franchise model is our focus on continuous training and support...`,
      date: 'October 5, 2024',
    },
    {
      id: 9,
      title: 'Aekaksh Franchise: Low Investment, High Returns',
      content: `Investing in an Aekaksh franchise is a cost-effective way to enter the lucrative education market...`,
      date: 'October 4, 2024',
    },
    {
      id: 10,
      title: 'How to Become an Aekaksh Franchisee: A Step-by-Step Guide',
      content: `Becoming an Aekaksh franchisee is a simple and straightforward process. Start by filling out our online application form...`,
      date: 'October 3, 2024',
    },
    // Adding the new blog posts
    {
      id: 11,
      title: 'Join the Aekaksh Play School Franchise Revolution: Nurturing Tomorrow’s Leaders',
      content: `Start your journey towards shaping young minds and building a legacy in early childhood education.`,
      date: 'October 2, 2024',
    },
    {
      id: 12,
      title: 'Aekaksh Play School: Empowering Women Entrepreneurs in Early Education',
      content: `Unlock a rewarding career path with a franchise that values empowerment and growth.`,
      date: 'October 1, 2024',
    },
    {
      id: 13,
      title: 'Transformative Learning with Aekaksh Play School: Your Franchise Opportunity Awaits',
      content: `Step into a world where innovation in education meets business success.`,
      date: 'September 30, 2024',
    },
    {
      id: 14,
      title: 'Invest in the Future with Aekaksh Play School: Franchise with Purpose',
      content: `Discover how owning an Aekaksh franchise can secure your future and inspire the next generation.`,
      date: 'September 29, 2024',
    },
    {
      id: 15,
      title: 'Build a Legacy with Aekaksh Play School: Franchise Opportunities in Hyderabad',
      content: `Seize the opportunity to lead the way in premium early childhood education.`,
      date: 'September 28, 2024',
    },
    {
      id: 16,
      title: 'Why Aekaksh Play School Franchise is the Smartest Investment for 2024',
      content: `Invest in a play school franchise that promises growth, stability, and long-term success.`,
      date: 'September 27, 2024',
    },
    {
      id: 17,
      title: 'From Dreams to Reality: Start Your Aekaksh Play School Franchise Today',
      content: `Turn your passion for education into a thriving business with Aekaksh.`,
      date: 'September 26, 2024',
    },
    {
      id: 18,
      title: 'Aekaksh Play School Franchise: Where Innovation Meets Early Education',
      content: `Be part of a pioneering brand that’s redefining the standards of preschool education.`,
      date: 'September 25, 2024',
    },
    {
      id: 19,
      title: 'Aekaksh Play School: Helping You Build a Bright Future for Children and Yourself',
      content: `Shape the leaders of tomorrow with a franchise model built for success.`,
      date: 'September 24, 2024',
    },
    {
      id: 20,
      title: 'Secure Your Place in the Education Sector with Aekaksh Play School Franchise',
      content: `Join the franchise transforming education one child at a time.`,
      date: 'September 23, 2024',
    },
    {
      id: 21,
      title: 'Aekaksh Play School: The Future of Early Education in Your Hands',
      content: `Become a franchise partner and lead the way in holistic, child-centered learning.`,
      date: 'September 22, 2024',
    },
    {
      id: 22,
      title: 'Aekaksh Play School Franchise: Empowering Entrepreneurs, Transforming Education',
      content: `Step into a proven franchise model that delivers both personal fulfillment and financial success.`,
      date: 'September 21, 2024',
    },
    {
      id: 23,
      title: 'Lead the Change in Early Childhood Education with Aekaksh Play School',
      content: `Become part of an innovative play school network that puts children first.`,
      date: 'September 20, 2024',
    },
    {
      id: 24,
      title: 'Franchise with Aekaksh Play School: Building Foundations, Inspiring Futures',
      content: `Inspire the next generation while building a successful business with Aekaksh.`,
      date: 'September 19, 2024',
    },
    {
      id: 25,
      title: 'Why Aekaksh Play School Franchise is a Game-Changer for New Entrepreneurs',
      content: `Start your entrepreneurial journey with the backing of a trusted and growing brand.`,
      date: 'September 18, 2024',
    },
    {
      id: 26,
      title: 'Aekaksh Play School Franchise: Creating a Path for Women Entrepreneurs',
      content: `Discover exclusive opportunities to own a franchise designed for growth and empowerment.`,
      date: 'September 17, 2024',
    },
    {
      id: 27,
      title: 'Invest in Education, Invest in Success: Aekaksh Play School Franchise Opportunity',
      content: `Join an educational movement that is committed to nurturing young minds and creating change.`,
      date: 'September 16, 2024',
    },
    {
      id: 28,
      title: 'Aekaksh Play School Franchise: The Key to Your Entrepreneurial Success',
      content: `Take charge of your future with a low-investment, high-impact play school franchise.`,
      date: 'Nov 20, 2024',
    },
    {
      id: 29,
      title: 'Own a Piece of the Future: Aekaksh Play School Franchise Opportunities in India',
      content: `Empower the leaders of tomorrow with a franchise built on innovation and care.`,
      date: 'Nov 21, 2024',
    },
    {
      id: 30,
      title: 'Aekaksh Play School: Leading the Way in Premium Early Childhood Education',
      content: `Partner with a brand that’s reshaping how early education is delivered and experienced.`,
      date: 'Nov 22, 2024',
    },
    {
      id: 31,
      title: 'Why Franchise with Aekaksh Play School? Grow with Us, Impact Generations',
      content: `Expand your horizons with a play school that’s driving educational excellence nationwide.`,
      date: 'Nov 23, 2024',
    },
    {
      id: 32,
      title: 'Become a Leader in Education: Franchise with Aekaksh Play School',
      content: `Transform your passion for education into a successful business model with our support.`,
      date: 'Nov 24, 2024',
    },
    {
      id: 33,
      title: 'Aekaksh Play School Franchise: Your Gateway to a Successful Business',
      content: `Join a community dedicated to fostering growth and creativity in early education.`,
      date: 'Nov 25, 2024',
    },
    {
      id: 34,
      title: 'Aekaksh Play School: Join Us in Making a Difference in Early Childhood Education',
      content: `Empower your community by providing quality education and care to young learners.`,
      date: 'Nov 26, 2024',
    },
    {
      id: 35,
      title: 'Aekaksh Play School: Innovating Early Education through Franchising',
      content: `Be a part of a transformative journey that’s setting new standards in preschool education.`,
      date: 'Nov 27, 2024',
    },
    {
      id: 36,
      title: 'Why Aekaksh Play School is Your Best Franchise Choice in 2024',
      content: `Explore the unique benefits of partnering with Aekaksh in the ever-evolving education landscape.`,
      date: 'Nov 28, 2024',
    },
    {
      id: 37,
      title: 'Aekaksh Play School: Franchise Opportunities for a Better Tomorrow',
      content: `Explore how owning a franchise with Aekaksh helps you make a difference in education.`,
      date: 'Nov 29, 2024',
    },
    {
      id: 38,
      title: 'Aekaksh Play School: Building Confidence in Young Minds',
      content: `Partner with us to foster creativity and confidence in the leaders of tomorrow.`,
      date: 'Nov 30, 2024',
    },
    {
      id: 39,
      title: 'From Education to Enterprise: Aekaksh Play School Franchise Success Stories',
      content: `Discover inspiring stories of entrepreneurs who made it big with Aekaksh.`,
      date: 'Dec 1, 2024',
    },
    {
      id: 40,
      title: 'Why Aekaksh Play School is the Future of Early Education Franchising',
      content: `Learn why our franchise model is the go-to choice for aspiring entrepreneurs.`,
      date: 'Dec 2, 2024',
    },
    {
      id: 41,
      title: 'Empowering Local Communities Through Aekaksh Play School Franchises',
      content: `Make an impact in your community by providing top-notch early education.`,
      date: 'Dec 3, 2024',
    },
    {
      id: 42,
      title: 'Aekaksh Play School: Pioneering Educational Excellence Through Franchises',
      content: `Be part of a brand that prioritizes excellence and innovation in education.`,
      date: 'Dec 4, 2024',
    },
    {
      id: 43,
      title: 'Top Reasons to Choose Aekaksh Play School for Your Franchise Journey',
      content: `Dive into the many reasons why Aekaksh is the right choice for education entrepreneurs.`,
      date: 'Dec 5, 2024',
    },
    {
      id: 44,
      title: 'Secure Your Future with Aekaksh Play School Franchise Opportunities',
      content: `Step into the world of education franchising with a trusted brand.`,
      date: 'Dec 6, 2024',
    },
    {
      id: 45,
      title: 'Innovation Meets Opportunity: Aekaksh Play School Franchise Benefits',
      content: `Discover how Aekaksh combines innovation with business opportunities.`,
      date: 'Dec 7, 2024',
    },
    // {
    //   id: 46,
    //   title: 'Grow with Aekaksh: Franchise Training and Support Programs',
    //   content: `Our comprehensive support ensures your franchise thrives.`,
    //   date: 'Dec 8, 2024',
    // },
    // {
    //   id: 47,
    //   title: 'The Aekaksh Impact: Changing Lives Through Early Childhood Education',
    //   content: `Be part of a movement that puts children at the forefront of education.`,
    //   date: 'Dec 9, 2024',
    // },
    // {
    //   id: 48,
    //   title: 'Aekaksh Play School: A Franchise Designed for the Modern Entrepreneur',
    //   content: `Step into a franchise tailored for the challenges of today.`,
    //   date: 'Dec 10, 2024',
    // },
    // {
    //   id: 49,
    //   title: 'Franchise Ownership Simplified with Aekaksh Play School',
    //   content: `We make franchise ownership straightforward and rewarding.`,
    //   date: 'Dec 11, 2024',
    // },
    // {
    //   id: 50,
    //   title: 'Aekaksh Play School: Blending Business with Social Impact',
    //   content: `Discover how our franchise model balances profitability with purpose.`,
    //   date: 'Dec 12, 2024',
    // },
    // {
    //   id: 51,
    //   title: 'Aekaksh Play School: Your Partner in Educational Success',
    //   content: `Join a brand committed to delivering excellence in education.`,
    //   date: 'Dec 13, 2024',
    // },
    // {
    //   id: 52,
    //   title: 'Aekaksh Play School: Franchise Benefits You Can Count On',
    //   content: `From training to support, learn how we set you up for success.`,
    //   date: 'Dec 14, 2024',
    // },
    // {
    //   id: 53,
    //   title: 'Creating Opportunities for Women Entrepreneurs with Aekaksh Play School',
    //   content: `Step into entrepreneurship with a franchise that values empowerment.`,
    //   date: 'Dec 15, 2024',
    // },
    // {
    //   id: 54,
    //   title: 'Aekaksh Play School Franchise: Excellence in Every Classroom',
    //   content: `Lead a franchise that delivers world-class education experiences.`,
    //   date: 'Dec 16, 2024',
    // },
    // {
    //   id: 55,
    //   title: 'Join the Aekaksh Play School Network: A Franchise with Purpose',
    //   content: `Be part of a thriving franchise network dedicated to education.`,
    //   date: 'Dec 17, 2024',
    // },
    // {
    //   id: 56,
    //   title: 'Aekaksh Play School: The Perfect Franchise for Passionate Educators',
    //   content: `Turn your passion for teaching into a fulfilling business venture.`,
    //   date: 'Dec 18, 2024',
    // },
    // {
    //   id: 57,
    //   title: 'Shape the Future of Education with an Aekaksh Play School Franchise',
    //   content: `Join a brand that is redefining how early childhood education is delivered.`,
    //   date: 'Dec 19, 2024',
    // },
    // {
    //   id: 58,
    //   title: 'Why Entrepreneurs Choose Aekaksh Play School Franchises',
    //   content: `Learn why Aekaksh is the preferred choice for aspiring entrepreneurs.`,
    //   date: 'Dec 20, 2024',
    // },
    // {
    //   id: 59,
    //   title: 'From Classroom to Boardroom: Aekaksh Franchise Success Stories',
    //   content: `Read about franchisees who turned dreams into reality with Aekaksh.`,
    //   date: 'Dec 21, 2024',
    // },
    // {
    //   id: 60,
    //   title: 'Aekaksh Play School: An Opportunity to Make a Difference',
    //   content: `Be part of a franchise network that’s shaping the future of education.`,
    //   date: 'Dec 22, 2024',
    // },
    // {
    //   id: 61,
    //   title: 'Aekaksh Play School Franchise: Your Gateway to Financial Independence',
    //   content: `Take control of your future with a franchise designed for success.`,
    //   date: 'Dec 23, 2024',
    // },
    // {
    //   id: 62,
    //   title: 'Empowering Entrepreneurs Through Aekaksh Play School Franchises',
    //   content: `Join a movement that is transforming education and creating leaders.`,
    //   date: 'Dec 24, 2024',
    // },
    // {
    //   id: 63,
    //   title: 'Aekaksh Play School Franchise: Building Communities, One Child at a Time',
    //   content: `Be part of a franchise that prioritizes community and education.`,
    //   date: 'Dec 25, 2024',
    // },
    // {
    //   id: 64,
    //   title: 'Aekaksh Play School: Invest in Education, Invest in the Future',
    //   content: `Discover how our franchise can be your pathway to success.`,
    //   date: 'Dec 26, 2024',
    // },
    // {
    //   id: 65,
    //   title: 'Aekaksh Play School: Leading the Way in Educational Franchising',
    //   content: `Be part of a brand that’s setting new benchmarks in early education.`,
    //   date: 'Dec 27, 2024',
    // },
    // {
    //   id: 66,
    //   title: 'Explore Franchise Opportunities with Aekaksh Play School',
    //   content: `Learn how our franchise model is transforming the education sector.`,
    //   date: 'Dec 28, 2024',
    // },
  ];

  return (
    <div>
      <Navbar />

      <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Latest News<span className="unique-allyouneed-title-purple"> and </span>Blogs</span>
      </h1>

      <div className="news-blogs">
        {blogPosts.map((post) => (
          <div key={post.id} className="news-blogs__item">
            <h2 className="news-blogs__title">{post.title}</h2>
            <p className="news-blogs__content">{post.content}</p>
            {/* <small>{post.date}</small> */}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default NewsBlogs;



