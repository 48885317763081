// FloatingCallButton.js
import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa'; // You can use any icon library
import './FloatingCallButton.css'; // Import the external CSS file

const FloatingCallButton = () => {
  return (
    <a
      href="tel:+911234567890" // Replace with your phone number
      className="floating-call-button"
    >
      <FaPhoneAlt size={24} />
    </a>
  );
};

export default FloatingCallButton;
