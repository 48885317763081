import React from 'react';
import './Common.css';
import Footer from './Footer';
import Navbar from '../components/Navbar';

const PrivacyPolicy = () => {
    return (
        <>
        <Navbar />
        
        <section className="privacyPolicySection" id='main-section'>
            <h3>Privacy Policy</h3>
            <p><strong>Effective Date:</strong> [Insert Date]</p>
            <p><strong>Overview:</strong></p>
            <p>
                Aekaksh School ("we," "our," "us") is committed to protecting the privacy of our franchisees and website users. This Privacy Policy explains how we collect, use, and share personal information when you engage with our franchise opportunities.
            </p>
            <h4>Information We Collect:</h4>
            <ul>
                <li><strong>Personal Information:</strong> We may collect personal information such as your name, contact details, financial information, and other data necessary for processing your franchise application.</li>
                <li><strong>Usage Data:</strong> We may collect data on how you interact with our website, including IP address, browser type, and pages viewed.</li>
                <li><strong>Cookies:</strong> We use cookies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
            </ul>
            <h4>How We Use Your Information:</h4>
            <ul>
                <li><strong>Application Processing:</strong> To assess and process your franchise application.</li>
                <li><strong>Communication:</strong> To contact you regarding your application, provide updates, and respond to your inquiries.</li>
                <li><strong>Marketing:</strong> To send you promotional materials and information about our services, provided you have consented to receive such communications.</li>
                <li><strong>Compliance:</strong> To comply with legal obligations and protect the rights and safety of our company and stakeholders.</li>
            </ul>
            <h4>Information Sharing:</h4>
            <ul>
                <li><strong>With Service Providers:</strong> We may share your information with trusted service providers who assist us in operating our business.</li>
                <li><strong>Legal Obligations:</strong> We may disclose your information if required by law or to protect our legal rights.</li>
            </ul>
            <h4>Security:</h4>
            <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or destruction.</p>
            <h4>Your Rights:</h4>
            <ul>
                <li><strong>Access and Correction:</strong> You have the right to request access to and correct your personal information.</li>
                <li><strong>Data Deletion:</strong> You can request the deletion of your personal information, subject to legal and contractual obligations.</li>
                <li><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us at any time.</li>
            </ul>
            <h4>Changes to the Privacy Policy:</h4>
            <p>We may update this Privacy Policy periodically. Changes will be posted on this page with an updated effective date.</p>
            <p><strong>Contact Us:</strong> For any questions regarding this Privacy Policy, please contact us at [Insert Contact Information].</p>
        </section>
        
        <Footer />
        
        </>


    );
};

export default PrivacyPolicy;
