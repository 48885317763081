import React from "react";
import "./PlanningAndStrategy.css";

const topics = [
  {
    title: "Women Empowerment",
    img: './images/Personal1.png', 
  },
  {
    title: "Business Opportunities",
    img: './images/Personal2.png', 
  },
  {
    title: "Best Play School Model",
    img: './images/Personal3.png', 
  },
  {
    title: "Sports Management",
    img: './images/Personal4.png', 
  },
];

const topics2 = [
  {
    title: "Artificial Intelligence Interaction",
    img: './images/Personal5.png', 
  },
  {
    title: "Art Learning",
    img: './images/Personal6.png', 
  },
  {
    title: "Health Maintenance for Children",
    img: './images/Personal7.png', 
  },
  {
    title: "Money Management for Students",
    img: './images/Personal8.png', 
  },
];

const PlanningAndStrategy = () => {
  return (
    <section className="planningAndStrategySection">
      <div className="contentContainer">
        <div className="leftSide p-img">
          <div>
            {topics.map((topic, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <h2 style={{ color: "#ff09c2" }}>{topic.title}</h2>
                <img src={topic.img} alt={topic.title} />
              </div>
            ))}
          </div>
        </div>
        <div className="rightSide p-img">
          <div>
            {topics2.map((topic, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <h2 style={{ color: "#ff09c2" }}>{topic.title}</h2>
                <img src={topic.img} alt={topic.title} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanningAndStrategy;