import React from 'react';
import './EmpowerWomen.css'; // Assuming you have a separate CSS file

const EmpowerWomen = () => {
    return (
        <section className="empowerWomenSection">
            <div className="empowerWomenContainer">
                <div className="empowerWomenContent">
                    {/* <h3>Empowering Women Entrepreneurs: Special Offer</h3> */}
                    <p><strong>Aekaksh School’s Commitment to Women in Business:</strong></p>
                    <ul>
                        <li><strong>Reduced Franchise Fee:</strong> Women entrepreneurs can avail an additional 10% discount on the standard franchise fee, making this an attractive opportunity for women seeking to enter the education sector.</li>
                        <li><strong>Tailored Leadership Training:</strong> Exclusive training programs designed to empower women with the leadership and management skills necessary to succeed in the competitive early childhood education landscape.</li>
                        <li><strong>Networking and Mentorship:</strong> Access to a vibrant community of successful women entrepreneurs and leaders in education, providing invaluable networking opportunities and mentorship.</li>
                    </ul>
                </div>
                {/* <div className="empowerWomenImage">
                    <img src="https://www.aekakshschool.com/assets/img/gallery/p-66.jpg" alt="Empowering Women Entrepreneurs" />
                </div> */}

                <div className="empowerWomenContent" >
                <img  src="./images/contact.jpg" alt="Empowering Women Entrepreneurs" />
                    {/* <p style={{margin:'auto',textAlign:'center',color:'#ff09c2'}}><strong>Contact :</strong> Richy Dastani</p> */}
                    <ul >
                        <h2 style={{margin:'auto',textAlign:'center',}}><strong>Contact no:</strong>+91 9281042708</h2>
                        
                    </ul>
                </div>

            </div>
        </section>
    );
};

export default EmpowerWomen;
