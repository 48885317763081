import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com"; // Ensure you have this installed via `npm install emailjs-com`
import { Link } from "react-router-dom"; // Make sure this is properly set up for routing
import CustomAlert from "./CustomAlert"; // Assuming you have an alert component
import Navbar from "./Navbar";
import Footer from "../tc/Footer";

const ContactPage = () => {
  // Scroll animation effect
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Form state management
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Mobile: "",
    Message: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const serviceID = "service_2veqkmd"; // Replace with your EmailJS service ID
    const templateID = "template_8ii2m3b"; // Replace with your EmailJS template ID
    const publicKey = "lSod9Ho16fFszMT24"; // Your Public Key

    emailjs
      .send(
        serviceID,
        templateID,
        {
          from_name: formData.Name,
          reply_to: formData.Email,
          message: `Name: ${formData.Name}\nMobile: ${formData.Mobile}\nMessage: ${formData.Message}`,
        },
        publicKey
      )
      .then((response) => {
        setAlertMessage(
          "Your email has reached us. Our coordinator will contact you shortly."
        );
        setShowAlert(true);
      })
      .catch((error) => {
        setAlertMessage(
          "There was an issue sending your message. Please try again."
        );
        setShowAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Navbar />

      <div className="contact-page">
        {/* SEO Optimized Meta Tags */}
        <meta
          name="description"
          content="Contact Aekaksh School for franchise opportunities. Get in touch with our team for more details about our educational mission."
        />
        <meta
          name="keywords"
          content="Aekaksh School, play school franchise, contact, education, Hyderabad, preschool business"
        />

        <div className="admissionformsection01 row animatable">
          <div className="col-md-7 col-xs-12">
            <div className="meta-section row">
              <div
                className="col-md-8 col-xs-12"
                style={{
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h2 style={{ color: "#ff09c2" }}>
                  {/* "<span style={{ color: '#0d2369' }}>Email :</span>  <span style={{ color: '#0d2369' }}>Women Entrepreneurs</span> Ready
                to Make a Big Impact!{" "}
                <span style={{ color: "#0d2369" }}>
                  Call for Franchises Now – Be a Part of Our Growing <span style={{ color: '#ff09c2' }}>Success!</span>"
                </span> */}
                  <span style={{ color: "#0d2369" }}>Contact Today</span> <br />
                  <span style={{ color: "#0d2369" }}>Email :</span>{" "}
                  aekakshfranchise@gmail.com <br />
                  <span style={{ color: "#0d2369" }}>Phone :</span> +91
                  9281042708
                </h2>
              </div>
              <div className="col-md-4 mobhide">
                <div className="landing-img-wrapper">
                  <img src="./images/customer-care.png" alt="yellow-girl" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 admissionformsection-sec-2">
            <div className="commonform animatable">
              <div className="form-card col-md-12">
                <h3>Enquiry Now</h3>
                <form method="post" onSubmit={handleSubmit}>
                  <div className="form-content">
                    <input
                      className="form-input-box"
                      name="Name"
                      placeholder="Full Name"
                      type="text"
                      value={formData.Name}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input-box"
                      name="Email"
                      placeholder="Email Id"
                      type="email"
                      value={formData.Email}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input-box"
                      name="Mobile"
                      placeholder="Contact Number"
                      type="tel"
                      value={formData.Mobile}
                      onChange={handleChange}
                      required
                    />
                    <textarea
                      className="form-input-box"
                      name="Message"
                      placeholder="Your Message"
                      rows="4"
                      value={formData.Message}
                      onChange={handleChange}
                      required
                    />
                    <div className="check-box-row">
                      <label>
                        <input
                          required
                          type="checkbox"
                          className="form-check-box"
                        />
                      </label>
                      <div>
                        <p>
                          By ticking the box and submitting this form, you agree
                          to the processing of your personal data in accordance
                          with the{" "}
                          <Link
                            to="/privacy-policy"
                            style={{ color: "#ff09c2" }}>
                            Privacy Policy
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="form-button-container">
                      <button
                        type="submit"
                        className="form-button"
                        disabled={isLoading}>
                        {isLoading ? <div className="loader"></div> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Alert Component */}
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
      </div>

      <Footer />
    </>
  );
};

export default ContactPage;
