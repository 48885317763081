import React, { useEffect } from 'react';
import './AllYouNeedSection.css'; // Make sure to link your CSS file

const AllYouNeedSection = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    
    <div className="unique-allyouneed">
      <h1 className="unique-allyouneed-title">
        <span className="unique-allyouneed-title-black block animatable moveUp">All You<span className="unique-allyouneed-title-purple"> Need </span>Is</span>
      </h1>
      
      <div className="unique-allyouneed-row">
        <div className="unique-card-width unique-nopadding unique-flexcenter">
          <div className="unique-allyouneed-card">
            <div className="unique-card-img-wrapper unique-flexcenter">
              <img src="./images/school-icon.png" alt="space" />
            </div>
            <div className="unique-card-content-wrapper unique-flexcenter">
              {/* <p className="unique-grey">Property investment</p> */}
              <h3 className="unique-pink">Property investment</h3>
              {/* <p>of Space</p> */}
            </div>
          </div>
        </div>
        
        <div className="unique-card-width unique-nopadding unique-flexcenter">
          <div className="unique-allyouneed-card">
            <div className="unique-card-img-wrapper unique-flexcenter">
              <img src="./images/money-icon.png" alt="investment" />
            </div>
            <div className="unique-card-content-wrapper unique-flexcenter">
              {/* <p className="unique-grey">Upto</p> */}
              <h3 className="unique-pink">Equity Investment</h3>
              {/* <p>of Investment</p> */}
            </div>
          </div>
        </div>
        
        <div className="unique-card-width unique-nopadding unique-flexcenter">
          <div className="unique-allyouneed-card">
            <div className="unique-card-img-wrapper unique-flexcenter">
              <img src="./images/love-icon.png" alt="passion" />
            </div>
            <div className="unique-card-content-wrapper unique-flexcenter">
              {/* <p className="unique-grey">Nurturing</p> */}
              <h3 className="unique-pink">Entrepreneurial Investment</h3>
              {/* <p>Minds</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>


  );
}

export default AllYouNeedSection;
