

import React from 'react';
import './Franchise.css';

const Franchise = () => {
  return (

    <>
    
    <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Franchise<span className="unique-allyouneed-title-purple"> Model </span>Options</span>
      </h1>
      
    <div className="unique-container">
      <div className="unique-box unique-box1">
        <img 
          src="./images/aa-img-01.jpg" 
          alt="Franchise Opportunity" 
          className="unique-image"
        />
      </div>
      <div className="unique-box unique-box2">
        <h2 className="unique-title">"<span style={{color:'#0d2369'}}>Elevate</span> Your Journey, Transform Lives – Special Offers for <span style={{color:'#0d2369'}}>Women Entrepreneurs</span> with Aekaksh School in <span style={{color:'#0d2369'}}>Hyderabad!</span>"</h2>
        
        <h3 className="unique-subtitle">Benefits:</h3>
        <ul className="unique-list">
          <li><strong>Instant Capital Generation:</strong> This model facilitates the rapid accrual of funds for the parent company, providing immediate financial inflow that can be reinvested into further growth and development.</li>
          <li><strong>Simplified Financial Management:</strong> Franchisees appreciate the clarity and simplicity of a one-time payment, eliminating the need for ongoing financial tracking and royalty calculations.</li>
          <li><strong>First-Mover Advantage:</strong> Early franchisees can capitalize on this limited-time offer, securing a branch with a significantly lower initial investment, positioning themselves strategically in the market.</li>
        </ul>
      </div>
    </div>

    <div className="unique-container2">

    <div className="unique-box unique-box2">
        <h2 className="unique-title">Success starts with a single step</h2>
        <ul className="unique-list">
          <li>Every great journey begins with a decision to move forward. By choosing to join the Aekaksh family, you're not just taking a step—you're paving the way for future generations to thrive.</li>
          {/* <li><strong>Exclusive 2025 Academic Year Offer:</strong> Secure your branch with a reduced one-time franchise fee of ₹5 lakhs for the first five franchisees.</li> */}
        </ul>
        <h3 className="unique-subtitle">Benefits:</h3>
        <ul className="unique-list">
          <li><strong>Sustainable Revenue Stream:</strong> This model ensures a steady, long-term income for the parent company, aligning the financial success of the franchisor with that of the franchisee.</li>
          <li><strong>Mutual Success Incentive:</strong> The royalty structure incentivizes both parties to actively contribute to the school’s growth and success, fostering a collaborative partnership.</li>
          {/* <li><strong>First-Mover Advantage:</strong> Early franchisees can capitalize on this limited-time offer, securing a branch with a significantly lower initial investment, positioning themselves strategically in the market.</li> */}
        </ul>
      </div>


      <div className="unique-box unique-box1">
        <img 
          src="./images/aa-img-02.jpg" 
          alt="Franchise Opportunity" 
          className="unique-image"
        />
      </div>
      
    </div>

    <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Empowering<span className="unique-allyouneed-title-purple"> Women </span>Entrepreneurs: Special Offer</span>
      </h1>
    
    </>
  );
};

export default Franchise;
