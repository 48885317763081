import React from 'react'
import Navbar from './Navbar'
import AdmissionForm from './admissionform'
import AllYouNeedSection from './AllYouNeedSection'
import Franchise from './Franchise'
import AllRoundSupport from './AllRoundSupport'
import EmpowerWomen from './EmpowerWomen'
import PlanningAndStrategy from './PlanningAndStrategy'
import FranchiseeSupport from './FranchiseeSupport'
import Footer from '../tc/Footer'
import WhyChooseUs from './WhyChooseUs'

const Home = () => {
  return (
    <>
    
    <Navbar />
    <AdmissionForm />
    <AllYouNeedSection />
    <Franchise />
    <EmpowerWomen />
    <AllRoundSupport />
    <PlanningAndStrategy />
    <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Strategic<span className="unique-allyouneed-title-purple"> Expansion </span>Plan</span>
      </h1>

      <h2 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Location<span className="unique-allyouneed-title-purple"> Targeting </span></span>
      </h2>

      <div style={{margin:'auto',textAlign:'center',width:'80%', marginBottom:'50px'}}>
      <p>
            <strong>Urban and Suburban Focus:</strong> Aekaksh School targets rapidly growing urban and suburban areas with a significant population of young families, ensuring a steady demand for high-quality early childhood education.
          </p>
      </div>
<FranchiseeSupport />

<div className="video-container" style={{margin:'auto',textAlign:'center',justifyContent:'center'}}>
        <iframe
          width="100%"
          height="350px"
          src="https://www.youtube.com/embed/jfSXAXde9Ek?si=0bs0cJWeCtkoB0n3"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

<h2 style={{margin:'auto',textAlign:'center',marginBottom:'20px', marginTop:'50px'}}>
        <span className="unique-allyouneed-title-purple"> Long-Term Vision: <span className="unique-allyouneed-title-black">Leveraging Franchise Success for Formal School Expansion </span></span>
      </h2>

      <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span className="unique-allyouneed-title-black">Future<span className="unique-allyouneed-title-purple"> Investor </span>Attraction</span>
      </h1>

      <p style={{margin:'auto',textAlign:'center',marginBottom:'20px', width:'90%'}}>
        <span className="unique-allyouneed-title-purple"> Security for Investors:  <span className="unique-allyouneed-title-black"> The successful establishment and operation of these franchise branches will be leveraged as collateral to attract future investors, enabling the expansion of Aekaksh into formal schooling. This strategic use of assets underscores the long-term vision and growth potential of the Aekaksh brand.</span></span>
      </p>

      <WhyChooseUs />
      
      <Footer />
    </>
  )
}

export default Home