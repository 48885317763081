import React from 'react'
import '../style.css'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
    <div className='nav-logo'>
    <Link to='/' >
    <img src='./images/Aekaksh Logo R.png' />
    </Link>
    </div>
    
    </>
  )
}

export default Navbar