import React from 'react';
import './Common.css'
import Footer from './Footer';
import Navbar from '../components/Navbar';

const RefundPolicy = () => {
    return (
        <>
        <Navbar />
        
        <section className="refundPolicySection" id='main-section'>
            <h3>Refund Policy</h3>
            <p><strong>Effective Date:</strong> [Insert Date]</p>
            <h4>1. Overview:</h4>
            <p>This Refund Policy outlines the conditions under which franchise fees and other payments may be refunded by Aekaksh School.</p>
            <h4>2. Franchise Fee:</h4>
            <ul>
                <li><strong>Non-Refundable:</strong> The initial franchise fee is generally non-refundable. This fee covers the costs associated with setting up your franchise, including training, marketing, and support.</li>
                <li><strong>Exceptions:</strong> Refunds may be considered in exceptional circumstances, such as:
                    <ul>
                        <li><strong>Denial of Franchise:</strong> If your application is denied after payment, a full refund will be issued.</li>
                        <li><strong>Franchisee Withdrawal:</strong> If you withdraw your application before the agreement is signed, a partial refund may be issued, subject to administrative charges.</li>
                    </ul>
                </li>
            </ul>
            <h4>3. Royalty Fees:</h4>
            <ul>
                <li><strong>Non-Refundable:</strong> Royalty fees paid based on revenue or profit are non-refundable, as they are calculated based on actual earnings.</li>
            </ul>
            <h4>4. Training and Support Fees:</h4>
            <ul>
                <li><strong>Non-Refundable:</strong> Fees paid for training and support services are non-refundable once the services have been provided.</li>
            </ul>
            <h4>5. Refund Request Process:</h4>
            <p>If you believe you are entitled to a refund, you must submit a written request to Aekaksh School within [Insert Time Frame] of the payment date. The request should include:
                <ul>
                    <li>Your name and contact information.</li>
                    <li>The payment amount and date.</li>
                    <li>The reason for the refund request.</li>
                </ul>
            </p>
            <h4>6. Refund Decision:</h4>
            <p>Aekaksh School will review your refund request and make a decision within [Insert Time Frame]. The decision will be communicated to you in writing.</p>
            <h4>7. Payment of Refunds:</h4>
            <p>If a refund is approved, it will be processed using the original payment method within [Insert Time Frame]. You are responsible for any bank charges or fees associated with the refund process.</p>
            <h4>8. Changes to the Refund Policy:</h4>
            <p>Aekaksh School reserves the right to modify this Refund Policy at any time. Changes will be effective upon posting on the website or other communication channels.</p>
            <p><strong>Contact Us:</strong> For any inquiries regarding this Refund Policy, please contact us at [Insert Contact Information].</p>
        </section>
        
        <Footer />
        
        </>
    );
};

export default RefundPolicy;
