import React from 'react';
import './WhyChooseUs.css'; // Ensure you have the CSS file for styling

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <div className="box-why">
        <h2>Why Choose Aekaksh School?</h2>
        <p>Aekaksh School is synonymous with excellence in early childhood education. Our mission is to provide a nurturing and stimulating environment where young minds can flourish. As part of our ambitious expansion plan, we are inviting visionary entrepreneurs to join us in establishing Aekaksh School branches across India.</p>
      </div>
      <div className="box-why">
        <h2>Exclusive Franchise Opportunities:</h2>
        <p>With flexible franchise models designed to suit various investment capacities, Aekaksh School offers both one-time fee and royalty-based franchise options. Our unique proposition includes special offers for women entrepreneurs and early adopters, ensuring that you can join our mission with minimal financial burden and maximum support.</p>
      </div>
      <div className="box-why">
        <h2>Unmatched Support System:</h2>
        <p>From the moment you join the Aekaksh family, we are committed to your success. Our comprehensive training programs cover every aspect of school management, from curriculum delivery to effective marketing strategies. Additionally, our ongoing support ensures that you have the tools and guidance needed to overcome challenges and grow your branch.</p>
      </div>
      <div className="box-why">
        <h2>Invest in Your Future with Aekaksh School:</h2>
        <p>Franchising with Aekaksh School is more than just a business opportunity; it’s an investment in the future of education. As our franchisee, you will play a pivotal role in shaping young minds and contributing to the educational landscape of your community. Moreover, with our strategic expansion plan, your success today will lay the foundation for Aekaksh’s future growth into formal schooling, making your franchise a valuable asset for potential investors.</p>
      </div>
    </section>
  );
};

export default WhyChooseUs;
