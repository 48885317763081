import React from 'react';
import './FranchiseeSupport.css'; // Assuming you have a separate CSS file

const FranchiseeSupport = () => {
    return (
        <section className="franchiseeSupportSection">
            <div className="franchiseeSupportContainer">
                <div className="franchiseeSupportContent">
                <h1 style={{margin:'auto',textAlign:'center',marginBottom:'20px'}}>
        <span style={{color:'#fff'}}>Franchisee  <span className="unique-allyouneed-title-purple" style={{color:'#0d2369'}}>Support </span> and Training</span>
      </h1>
                    {/* <h2>Franchisee Support and Training</h2> */}
                    <ul>
                        <li>
                            <strong>Comprehensive Training:</strong> Franchisees receive extensive training in curriculum implementation, operational management, and effective marketing strategies, ensuring they are fully equipped to run a successful Aekaksh School branch.
                        </li>
                        <li>
                            <strong>Ongoing Assistance:</strong> A dedicated support team is available to assist with day-to-day operations, troubleshooting, and continuous improvement initiatives.
                        </li>
                    </ul>
                </div>
                <div className="franchiseeSupportImage">
    <img src="./images/FranchiseeSupport1.png" alt="Franchisee Support 1" />
    <img src="./images/FranchiseeSupport2.png" alt="Franchisee Support 2" />
    <img src="./images/FranchiseeSupport3.png" alt="Franchisee Support 3" />
    <img src="./images/FranchiseeSupport4.png" alt="Franchisee Support 4" />
</div>
            </div>
        </section>
    );
};

export default FranchiseeSupport;
