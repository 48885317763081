import React from "react";
import "./AllRoundSupport.css";

const features = [
  {
    id: 1,
    icon: "./images/school-icon.png",
    title: "Infrastructure & Ambience Design",
  },
  {
    id: 2,
    icon: "./images/Furniture-icon.png",
    title: "Furniture & Equipment",
  },
  {
    id: 3,
    icon: "./images/Researched-icon.png",
    title: "Researched Curriculum",
  },
  { id: 4, icon: "./images/Teaching-icon.png", title: "Teaching Aids" },
  {
    id: 5,
    icon: "./images/Business-icon.png",
    title: "Business Management Portal",
  },
  {
    id: 6,
    icon: "./images/Teacher Training.png",
    title: "Teacher Training & Skill Updating",
  },
  {
    id: 7,
    icon: "./images/Marketing-icon.png",
    title: "Marketing & Operational Support",
  },
  { id: 8, icon: "./images/Lead-icon.png", title: "Lead Management Support" },
];

const AllRoundSupport = () => {
  return (
    <section className="allRoundSupportSection">
      <div className="allRoundSupportContainer">
        <div className="allRoundSupportTitle textCenter">
          <h1
            style={{
              margin: "auto",
              textAlign: "center",
              marginBottom: "20px",
              fontSize:'35px'
            }}>
            <span className="unique-allyouneed-title-black">
              All<span className="unique-allyouneed-title-purple"> Round </span>
              Support
            </span>
          </h1>
        </div>
        <div className="featuresGrid">
          {features.map((feature) => (
            <div key={feature.id} className="featureBox">
              <img
                src={feature.icon}
                alt={feature.title}
                className="featureIcon"
              />
              <h3>{feature.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllRoundSupport;
